<!-- @format -->

<template>
	<div>
		<div
			id="saveButtons"
			class="margin-bottom-30; buttons-container"
			style="
				width: 100%;
				background-color: rgb(76, 92, 122);
				padding-bottom: 30px;
				margin-top: -30px;
			"
		>
			<b-button
				class="margin-top-30 margin-left-15"
				style="background-color: rgb(182, 90, 92); color: white; width: 250px"
				@click="onSave('save')"
				:disabled="disableButtons"
			>
				Leave Order as draft
			</b-button>
			<b-button
				class="margin-top-30 margin-left-15"
				style="background-color: rgb(61, 196, 134); color: white; width: 250px"
				@click="onSave('lock')"
				:disabled="disableButtons"
			>
				Order is ready to Dispatch
			</b-button>
			<b-button
				class="margin-top-30 margin-left-15"
				style="background-color: rgb(52, 136, 206); color: white; width: 250px"
				@click="onSave('view')"
				:disabled="disableButtons"
			>
				Save Order & view
			</b-button>
		</div>
		<div
			id="cancelButton"
			class="margin-bottom-30; buttons-container"
			style="background-color: rgb(76, 92, 122); padding-bottom: 30px"
		>
			<b-button
				class="margin-left-15"
				style="background-color: rgb(241, 70, 104); color: white; width: 250px"
				@click="cancelTheOrder"
			>
				Cancel The Order
			</b-button>
		</div>
	</div>
</template>

<script>
import LockOrderMutation from '@/_srcv2/pages/order/edit-order/subcomponents/update-order/graphql/LockOrderMutation.graphql'
import CancelOrderMutation from '@/_srcv2/graphql/order/mutations/cancelOrder.mutation.gql'
import { ref, computed } from '@vue/composition-api'
import { useMutation } from '@vue/apollo-composable'
import storeOrder from '@/_srcv2/pages/order/_shared/useOrderState.js'
import Router from '@/router'
export default {
	name: 'UpdateOrder',
	setup() {
		// ----------------------------------------------------------------------------
		const isOrderLocked = ref(false)
		// -------------------------------------------------------------------
		const disableButtons = ref(false)
		// ---------------------------------------------------------------------------
		const orderNumber = computed(() => reportOrderNumber.value)
		const { reportOrderNumber } = storeOrder()
		const { mutate: lockTheOrder } = useMutation(LockOrderMutation, () => ({
			variables: {
				order_number: orderNumber.value,
			},
		}))
		// -------------------------------------------------------------------------------
		const { mutate: cancelOrder } = useMutation(CancelOrderMutation, () => ({
			variables: {
				order_number: orderNumber.value,
			},
		}))
		// --------------------------------------------------------------------------------
		const setOrderStatus = (type) => {
			return new Promise((resolve, reject) => {
				if (type !== null || undefined) {
					switch (type) {
						case 'save':
							isOrderLocked.value = false
							break
						case 'lock':
							isOrderLocked.value = false
							break
						case 'view':
							isOrderLocked.value = true
							break
						default:
							isOrderLocked.value = false
					}
					resolve('done')
				} else {
					const reason = new Error('Action type is not found')
					reject(reason)
				}
			})
		}

		// -----------------------------------------------------------------
		const onSave = (type) => {
			setOrderStatus(type)
			if (type === 'save') {
				Router.push({ name: 'ViewOrder' })
			} else if (type === 'lock') {
				lockTheOrder().then(() => Router.push({ name: 'ViewOrder' }))
			} else {
				lockTheOrder().then(() => {
					Router.push({ name: 'GetOrderReport' })
				})
			}
		}
		// --------------------------------------------------------------------
		const cancelTheOrder = () => {
			if (confirm('Are you sure to cancel the Order!')) {
				cancelOrder().then(() => Router.push({ name: 'ViewOrder' }))
			}
		}
		// ---------------------------------------------------------------------------------------
		return { disableButtons, onSave, cancelTheOrder, orderNumber }
	},
}
</script>
<style>
.buttons-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 20 px;
}
</style>
